import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { MpriseAuthProvider, MpriseUser } from '@mprise/react-auth'
import { ErrorBoundary } from '../error-boundary'
import { usePersistentState } from '../shared/persistant-state'
import { fail } from '../shared/typescript'
import { useLazyQuery } from '@apollo/client'
import { GET_RESOURCE } from '../gql/resources'

export type ResourceSubset = {
  id: string
  code: string
  name: string
}

export const DefaultResourceContainer = ({ children }: { children: React.ReactNode }) => {
  const [_default, setDefault] = usePersistentState<ResourceSubset | null>(`__DEFAULT_RESOURCE`, null)

  const { t } = useTranslation()

  const { user } = MpriseAuthProvider.useContext()
  const allowedRoles = [`IDENTITYSERVER_USER_ADMIN`, `IDENTITYSERVER_TENANT_ADMIN`, `IDENTITYSERVER_ROLE_ADMIN`]
  const hasAdminRole = user?.roles.some(role => allowedRoles.includes(role))

  const [getResource] = useLazyQuery(GET_RESOURCE)

  useEffect(() => {
    ;(async () => {
      const subjectId = user ? (user as MpriseUser & { sub: string }).sub : null

      if (subjectId) {
        const { data } = await getResource({
          variables: {
            filter: {
              externalUserId: subjectId,
            },
          },
        })

        const resource = data?.resource
        if (!hasAdminRole && !resource) {
          fail(t(`NO_RESOURCE_FOUND`, { x: user?.email }))
        }

        if (!_default) {
          setDefault(
            resource
              ? {
                  code: resource?.code ?? ``,
                  id: resource?.id ?? ``,
                  name: resource?.name ?? ``,
                }
              : null,
          )
        }
      }
    })()
  }, [user])

  return (
    <ErrorBoundary handleUnrejectedPromise={true}>
      <DefaultResourceContainer.Context.Provider value={{ isAdmin: !!hasAdminRole, default: _default, setDefault }}>
        {children}
      </DefaultResourceContainer.Context.Provider>
    </ErrorBoundary>
  )
}

DefaultResourceContainer.Context = React.createContext<{
  isAdmin: boolean
  default: ResourceSubset | null
  setDefault: (s: ResourceSubset | null) => void
}>({
  isAdmin: false,
  default: {} as any /* TODO: make position properties optional */,
  setDefault: () => {
    throw Error(`DefaultResourceContainer missing`)
  },
})

DefaultResourceContainer.useDefault = () => React.useContext(DefaultResourceContainer.Context)
